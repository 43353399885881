import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Book, BookCategory } from '../../models/book.model';
import { BookShelfService, ShelfType } from '../../services/book-shelf.service';
import { AppService } from '../../services/app.service';
import { SwiperDirective } from '../../directives/swiper.directive';
import { SWIPER_SHELF_CONFIG, SWIPER_SHELF_CONFIG_MOBILE, SWIPER_SHELF_CONFIG_TABLET } from '../../pages/book-reader/book-reader.constants';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, filter, Subject, switchMap, take, takeUntil } from 'rxjs';
import Swiper from 'swiper';
import { fadeIn, fadeInOut } from '../../utils/animations';
import { AsyncPipe } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { ButtonComponent } from '../button/button.component';
import { IonicModule } from '@ionic/angular';
import { BookShelfBeanPromptComponent } from './components/book-shelf-bean-prompt/book-shelf-bean-prompt.component';
import { BookShelfCoverImageComponent } from './components/book-shelf-cover-image/book-shelf-cover-image.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-book-shelf',
  templateUrl: './book-shelf.component.html',
  styleUrls: ['./book-shelf.component.scss'],
  standalone: true,
  animations: [fadeInOut, fadeIn],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    AsyncPipe,
    RouterLink,
    ButtonComponent,
    SwiperDirective,
    IonicModule,
    TranslateModule,
    BookShelfBeanPromptComponent,
    BookShelfCoverImageComponent,
  ],
})
export class BookShelfComponent implements OnDestroy, OnInit {
  @Input({ required: true }) type!: ShelfType;
  @Input() category?: BookCategory; // Required only if ShelfType is Category
  @Input() theme: 'white' | 'blue' = 'white';

  @ViewChild(SwiperDirective, { read: ElementRef }) swiperRef!: ElementRef<{ swiper: Swiper }>;

  constructor(
    public appService: AppService,
    private translateService: TranslateService,
    private bookShelfService: BookShelfService,
    private ref: ChangeDetectorRef,
    private router: Router,
  ) {}

  private destroyed$ = new Subject<void>();
  private pageSize = 20;
  private currentPage = 0;

  get swiper(): Swiper {
    return this.swiperRef?.nativeElement?.swiper;
  }

  shelfType = ShelfType;
  swiperConfiguration = SWIPER_SHELF_CONFIG;

  isFirstLoading = true;
  isLoadingNewPage = false;
  isEverythingLoaded = false;

  books: Book[] = [];

  shelfTotalLength?: number;

  get shelfDisplayed(): boolean {
    return this.isFirstLoading || !!this.books.length;
  }

  // We initially get the first 10 books saved in the memory.
  // If the user scroll, it'll load new books from the backend
  ngOnInit(): void {
    this.bookShelfService.initialized$
      .pipe(
        filter(initialized => initialized),
        take(1),
        switchMap(() => this.bookShelfService.getBookRequest(this.type, this.category?.id)),
      )
      .subscribe({
        next: books => {
          this.books = books;
          this.isFirstLoading = false;

          setTimeout(() => {
            this.swiper?.slides && this.swiper?.updateSlides();
            this.ref.detectChanges();

            this.swiper?.on('slideChange', () => {
              const totalSlides = this.swiper?.slides?.length;
              const slidesPerView = Number(this.swiperConfiguration.slidesPerView);
              const lastIndex = totalSlides - slidesPerView;

              // We load new books when 17/20 slides are displayed
              if (this.swiper?.activeIndex >= lastIndex - 3 && !this.isEverythingLoaded && !this.isLoadingNewPage) {
                this.loadBooks();
              }

              this.ref.detectChanges();
            });
          }, 1);
        },
      });

    combineLatest([this.appService.isMobile$, this.appService.isTablet$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([isMobile, isTablet]) => {
        if (isMobile) {
          this.swiperConfiguration = SWIPER_SHELF_CONFIG_MOBILE;
        } else if (isTablet) {
          this.swiperConfiguration = SWIPER_SHELF_CONFIG_TABLET;
        } else {
          this.swiperConfiguration = SWIPER_SHELF_CONFIG;
        }
      });
  }

  get shelfRoute(): string {
    return '/shelf/' + (this.type === ShelfType.Category ? 'category/' + this.category?.id : this.type);
  }

  get title(): string {
    switch (this.type) {
      // Store features / popular & new shelves data in bookShelveService
      case ShelfType.Featured:
        return this.translateService.instant('PWA_bookShelf_BOW');
      case ShelfType.Popular:
        return this.translateService.instant('PWA_bookShelf_popularAmongstReaders');
      case ShelfType.New:
        return this.translateService.instant('PWA_bookShelf_newBooks');
      case ShelfType.ContinueReading:
        return this.translateService.instant('PWA_bookshelf_continueReading');
      case ShelfType.Favorites:
        return this.translateService.instant('myLibrary_bookShelf_myFavorites');
      case ShelfType.ReadAgain:
        return this.translateService.instant('myLibrary_bookShelf_readAgain');
      case ShelfType.FeaturedActive:
        return this.bookShelfService.featuredActiveBooksTitle$.value;
      case ShelfType.Category:
        if (!this.category?.id) {
          console.error('No category Id passed for category' + this.category?.id);
        }
        if (!this.category?.name.default) {
          console.error('No category Name passed for category' + this.category?.id);
        }
        return this.translateService.instant(this.category?.name.default as string);
    }
  }

  swipeBook(next: boolean): void {
    if (next) {
      this.swiper?.slideNext();
    } else {
      this.swiper?.slidePrev();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private loadBooks(): void {
    this.isLoadingNewPage = true;
    if (this.shelfDisplayed) {
      this.swiper?.update(); // Need to update the slides in order to display the loader
    }

    this.ref.detectChanges();
    this.currentPage = this.currentPage + 1;

    this.bookShelfService
      .getPagedBooksRequest(this.type, this.category?.id, this.currentPage * this.pageSize, this.pageSize)
      .pipe(take(1))
      .subscribe(paginatedBooks => {
        const books = paginatedBooks?.books ?? [];

        this.books.unshift(...books);
        this.ref.detectChanges();
        if (this.shelfDisplayed) {
          this.swiper?.updateSlides();
        }

        if ((paginatedBooks?.totalCount === this.books.length || paginatedBooks?.totalCount === 0) && this.shelfDisplayed) {
          this.isEverythingLoaded = true;
          // We enable loop here.
          this.swiperConfiguration = {
            ...this.swiperConfiguration,
            loop: true,
          };
          this.swiper?.updateSlides();
        }

        this.shelfTotalLength = paginatedBooks?.totalCount;
        this.isLoadingNewPage = false;
        this.books.length && this.swiper?.update();
        this.ref.detectChanges();
      });
  }

  openNotFinishedBook(): void {
    void this.router.navigate([`/book/${this.books[0].uuid}/reader`]);
  }

  goToHome(): void {
    void this.router.navigate(['home']);
  }
}
